import { DisplayCurrencyEnum } from "@/Api/generated";

export function getCurrencySymbol(currency?: DisplayCurrencyEnum) {
  if (currency === "USD" || currency === "CAD" || currency === "AUD") return "$";
  if (currency === "EUR") return "€";
  if (currency === "GBP") return "£";
  if (currency === "JPY") return "¥";
  if (currency === "CNY") return "¥";

  return "$";
}

export function dollarToCents(dollar: number) {
  return dollar * 100;
}

export function centsToDollar(cents: number) {
  return cents / 100;
}
