import type { TagProps } from "..";

import SvgIcon from "@/components/SvgIcon";

import Tag from "..";

/**
 * A tag used for upselling features, with an additional lock icon.
 */
export default function UpgradeTag(props: TagProps) {
  return (
    <span className="UpgradeTag">
      <Tag color="blue" text="Upgrade to view" strong {...props} />
      <SvgIcon name="lock" />
    </span>
  );
}
