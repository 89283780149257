import { ReactNode } from "react";

interface ChartKpiProps {
  children: ReactNode;
}

/**
 * This component is for consistent styling per our Design Language System.
 */
export default function ChartKpiTitle({ children }: ChartKpiProps) {
  return <span className="ChartKpiTitle">{children}</span>;
}
