import type { ReactNode } from "react";

import { animated, SpringValues } from "@react-spring/web";
import { forwardRef } from "react";

import ErrorBoundary from "@/components/ErrorBoundary";
import Spinner from "@/components/Spinner";

import { classnames } from "@/js/utils/cambio";

import { ErrorState } from "../EmptyState";
import SvgIcon, { IconsType } from "../SvgIcon";
import UpgradeTag from "../Tag/UpgradeTag";
import TextOverflow from "../TextOverflow";

export type CambioCardProps = {
  className?: string;
  /** Can be a string or a component for more complex behavior */
  title?: ReactNode;
  subtitle?: ReactNode;
  /** CTA contents in the header */
  actionBar?: ReactNode;
  children: ReactNode;
  /** Whereas locked is for when we show an UpgradeTag, disabled could be for any reason */
  disabled?: boolean;
  /** This card is feature-gated and we will show an UpgradeTag */
  locked?: boolean;
  /** When the data powering this card is in a loading state */
  isLoading?: boolean;
  /** When the data powering this card is in an error state */
  hasErrored?: boolean;
  /** A unit-like label for a chart nested underneath the chart title */
  label?: ReactNode;
  /** Spring props to be passed to the animated.div container */
  spring?: SpringValues;
  /** Icon to be displayed in the header */
  icon?: IconsType;
};

/**
 * Wrapper component used throughout the app to showcase some content in a card-like UI. The most
 * defining characteristic of the card is its rounded border (often used as a CSS grid child). But
 * it can take a number of different optional props for consistent UI, like a title, subtitle, or
 * header icon, as well as loading states for fetching card data.
 */
const CambioCard = forwardRef<HTMLDivElement, CambioCardProps>(
  (
    {
      title = "",
      className = "",
      subtitle = "",
      label = "",
      spring,
      locked,
      actionBar,
      children,
      disabled = false,
      isLoading = false,
      hasErrored = false,
      icon,
    },
    ref,
  ) => {
    return (
      <animated.div
        ref={ref}
        className={classnames("CambioCard", className, { disabled: disabled || locked })}
        style={spring}
      >
        {title || actionBar ?
          <header>
            <span>
              <h4>
                {icon ?
                  <SvgIcon name={icon} />
                : null}
                <TextOverflow>{title}</TextOverflow>
              </h4>
              {subtitle ?
                <p>{subtitle}</p>
              : null}
              {label ?
                <small>{label}</small>
              : null}
            </span>
            {actionBar || locked ?
              <span className="actions-bar">
                {locked ?
                  <UpgradeTag />
                : actionBar}
              </span>
            : null}
          </header>
        : null}
        <section>
          {isLoading ?
            <Spinner flavor="overlay" />
          : null}
          {hasErrored ?
            <ErrorState />
          : <ErrorBoundary>{children}</ErrorBoundary>}
        </section>
      </animated.div>
    );
  },
);

export default CambioCard;
